import "../../scss/styles.scss";
import "material-symbols";
import * as bootstrap from "bootstrap";

const header = document.querySelector(".header");

if (!header.classList.contains("header--scrolled")) {
    document.addEventListener("scroll", (scroll) => {
        if (window.scrollY > header.getBoundingClientRect().height) {
            header.classList.add("header--scrolled");
        } else {
            header.classList.remove("header--scrolled");
        }
    });
}
